(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/widgets/ticker/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/widgets/ticker/assets/javascripts/constants.js');

'use strict';

const TickerBrandTypes = {
  BRAND_BINGO: 'bingo',
  BRAND_CASINO: 'casino',
  BRAND_MOMANG: 'momang'
};
const TickerFeedTypes = {
  FEED_BINGO: 'bingo',
  FEED_SLOTS: 'slots',
  FEED_LIVE_CASINO: 'livecasino'
};
const TickerTypeTypes = {
  TYPE_WINNER: 'winners',
  TYPE_PROMO: 'promo'
};
const TickerConstants = {
  brand: {
    [TickerBrandTypes.BRAND_BINGO]: 'bingo',
    [TickerBrandTypes.BRAND_CASINO]: 'casino',
    [TickerBrandTypes.BRAND_MOMANG]: 'momang'
  },
  feed: {
    [TickerFeedTypes.FEED_BINGO]: 'bingo',
    [TickerFeedTypes.FEED_SLOTS]: 'slots',
    [TickerFeedTypes.FEED_LIVE_CASINO]: 'livecasino'
  },
  type: {
    [TickerTypeTypes.TYPE_WINNER]: 'winners',
    [TickerTypeTypes.TYPE_PROMO]: 'promo'
  }
};
if (typeof exports === 'object') {
  module.exports = {
    TickerBrandTypes,
    TickerFeedTypes,
    TickerTypeTypes,
    TickerConstants
  };
} else {
  setGlobal('svs.widget.ticker.constants', {
    TickerBrandTypes,
    TickerFeedTypes,
    TickerTypeTypes,
    TickerConstants
  });
}

 })(window);